import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => import('../components/Login')
        },
        {
            path: '/controlaccess',
            name: 'ControlAccess',
            component: () => import('../components/ControlAccess')
        },
        {
            path: '/informationevent',
            name: 'InformationEvent',
            component: () => import('../components/InformationEvent')
        },
        {
            path: '/screen',
            name: 'ScreenView',
            component: () => import('../components/ScreenView')
        },
        {
            path: '/:catchAll(.*)',
            name: "Redirect",
            component: () => import('../components/Login')

        }
    ]
})
export default router;